<template>
  <div v-if="isLoading">
    <v-progress-linear indeterminate></v-progress-linear>
  </div>
  <div v-else>
    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
      color="warning"
      timeout="-1"
      outlined
      absolute
      bottom
      right
      transition="slide-x-reverse-transition"
    >
      Associations to Engagements must be removed before a site location is
      deleted.

      <template v-slot:action="{ attrs }">
        <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          <span class="d-none" aria-hidden="true">Close</span>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-text>
      <v-card class="mx-auto mb-12">
        <section-header
          :isCollapsable="false"
          :target="() => this.$refs.siteLocationsDetailsContainer"
          >Details</section-header
        >
        <v-card-text>
          <v-container fluid ref="siteLocationsDetailsContainer">
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Site Name:</dt>
                <dd v-text="name"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Site Location ID:</dt>
                <dd>{{ siteLocationId }}</dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Site Trial Status:</dt>
                <dd>
                  <v-icon v-if="isActive" color="green"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon v-else color="red">mdi-cancel</v-icon>
                  {{ isActive ? "Active" : "Inactive" }}
                </dd>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>PI Name</dt>
                <dd v-text="piName"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Type of Site</dt>
                <dd>{{ typeOfSite | humanize }}</dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>IRB</dt>
                <dd>{{ irb | humanize }}</dd>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Address</dt>
                <dd v-text="location.street_address"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Building / Unit</dt>
                <dd v-text="location.unit_number"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>City</dt>
                <dd v-text="location.city"></dd>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>State</dt>
                <dd v-text="location.state"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Zip Code</dt>
                <dd v-text="location.zip_code"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Country</dt>
                <dd v-text="countryName"></dd>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-card-text>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import SectionHeader from "@admin/components/SectionHeader.vue";
import { humanize } from "@utils/filters/stringHelpers.js";

export default {
  name: "SiteLocationsView",
  components: {
    SectionHeader,
  },
  async beforeMount() {
    // Load site location
    this.isLoading = true;
    await this.getSiteLocation({
      id: this.id,
      engagementId: this.engagementId,
    });
    this.isLoading = false;

    this.setTitleObject(this.name);

    this.setBreadcrumbs();
  },
  filters: {
    humanize,
  },
  computed: {
    ...mapFields("siteLocations", {
      name: "siteLocation.name",
      piName: "siteLocation.piName",
      irb: "siteLocation.siteLocationIrbType",
      typeOfSite: "siteLocation.siteLocationType",
      location: "siteLocation.location",
      isActive: "siteLocation.isActive",
      siteLocationId: "siteLocationId",
      countryName: "siteLocation.countryName",
    }),
    ...mapGetters(["acceptRoles"]),
    showEditSiteLocationButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
    showDeleteSiteLocationButton() {
      return this.acceptRoles(["Admin", "Super User"]);
    },
  },
  data() {
    return {
      isLoading: false,
      snackbar: false,
      engagementId: this.$route.params.engagementId,
    };
  },
  destroyed() {
    this.clearSiteLocation();
    this.clearTitleObject();
  },
  methods: {
    ...mapActions("siteLocations", [
      "clearSiteLocation",
      "getSiteLocation",
      "deleteSiteLocation",
    ]),
    ...mapActions("navigationBar", [
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
  },
  async mounted() {
    const actionButtons = [];

    if (this.engagementId) {
      actionButtons.push({
        attributes: {
          to: "/engagements/view/:id".replace(":id", this.engagementId),
        },
        component: "BackButton",
        index: 0,
      });
    }
    if (this.showDeleteSiteLocationButton) {
      actionButtons.push({
        attributes: {
          onConfirm: () => {
            this.deleteSiteLocation(this.id).then(([success]) => {
              if (success) {
                this.$router.push({
                  name: "SiteLocationsIndex",
                  params: {},
                });
              } else {
                this.snackbar = true;
              }
            });
          },
        },
        component: "DeleteButton",
        index: 0,
      });
    }
    if (this.showEditSiteLocationButton) {
      actionButtons.push({
        attributes: {
          to: "/site-locations/edit/:id".replace(":id", this.id),
        },
        component: "EditButton",
        index: 1,
      });
    }
    this.setActionButtons(actionButtons);
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
dt {
  font-weight: bold;
  font-size: 1.1rem;
}
</style>
